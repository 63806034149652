import {onFind} from "@elements/init-modules-in-scope";
import {addClass} from "@elements/dom-utils";
import BlazeSlider from 'blaze-slider';

export function init() {
    onFind('.js-generic-slider__slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 3;
        const slidesToShowMobile = 1.5;
        const maxWidth = '(max-width: 767px)';
        let style = document.createElement('style');
        style.innerHTML = '.generic-slider__slides-to-show { --slides-to-show: ' + (window.matchMedia(maxWidth).matches ? slidesToShowMobile : slidesToShow) + ' !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);

        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            },
            [maxWidth]: {
                slidesToScroll: 1,
                slidesToShow: slidesToShowMobile,
                draggable: true,
            },
        });

        addClass('generic-slider__slides-to-show', baseElement);
        addClass('blaze-init', baseElement);
    });
}
import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {addClass} from "@elements/dom-utils";

export function init() {
    onFind('.js-teaser-slider-lg', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShowLarge = 3;
        const slidesToShow = 2;
        const slidesToShowMobile = 1.1;
        const minWidth = '(min-width: 768px)';
        const minWidthLarge = '(min-width: 1200px)';
        let style = document.createElement('style');
        style.innerHTML = '.teaser-slider-lg__slides-to-show { --slides-to-show: ' + (window.matchMedia(minWidthLarge).matches ? slidesToShowLarge : window.matchMedia(minWidth).matches ? slidesToShow : slidesToShowMobile) + ' !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);

        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShowMobile,
                draggable: true,
            },
            [minWidth]: {
                slidesToShow: slidesToShow,
            },
            [minWidthLarge]: {
                slidesToShow: slidesToShowLarge,
            },

        });

        addClass('teaser-slider-lg__slides-to-show', baseElement);
        addClass('blaze-init', baseElement);
    });
}
"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,toggleClass,hasClass, findAllIn, on, trigger, findIn, addClass, find, closest} from '@elements/dom-utils';


export function init() {
    onFind('.js-segment', function (baseElement) {
        let items = findAllIn('.js-segment__item', baseElement);
        let cursor = findIn('.js-segment__cursor', baseElement);
        let hoverId;
        let numberOfItems = items.length;

        //show cursor content
        items.map(item => {
             on('mousemove', e => {
                 moveCursor(e, cursor);
             }, baseElement);
             item.onmouseover = function()   {
                 addClass('is-active', cursor);
                 if(hoverId !== this.getAttribute('data-id')){
                     hoverId = this.getAttribute('data-id');
                     trigger('segment:itemchange', baseElement);
                 }
             };
             item.onmouseout = function()   {
                 removeClass('is-active', cursor);
             }
        });

        //scroll content in cursor
        baseElement.addEventListener('segment:itemchange', function(e) {
            let contents = findAllIn('.js-segment__cursor-content', baseElement);
            let contentWrapper = findIn('.js-segment__cursor-content-wrapper', baseElement);

            contents.map(content => {

                if(content.getAttribute('data-id') === hoverId){

                    //if content has no child elements then add class is-hidden to cursor
                    if(content.children.length === 0) {
                        addClass('is-hidden', cursor);
                    }else{
                        removeClass('is-hidden', cursor);
                    }

                    let offset;

                    addClass('is-active-item', content);

                    // let activeOffset = content.offsetHeight;
                    // content.style.height = `${activeOffset}px`;

                    if(hoverId === '0'){
                        offset = 0;
                    }else{
                        offset = (hoverId * (100 / numberOfItems)) * -1;
                    }
                    contentWrapper.style.transform = `translate3d(0, ${offset}%, 0)`
                } else {
                    removeClass('is-active-item', content);
                }
            });
        });
    });
}

function moveCursor(e, cursor) {
    const mouseY = e.clientY;
    const mouseX = e.clientX;

    cursor.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
}
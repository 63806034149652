"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on, addClass, removeClass,hasClass,toggleClass, findIn} from '@elements/dom-utils';
export function init () {
    onFind('.js-accordion', function (baseElement){
        let accordionItem = findAllIn('.js-accordion-item', baseElement);

        accordionItem.forEach(function (item){
            let accordionCollapse = findIn('.js-accordion-collapse', item);
            let accordionBtnIcon = findIn('.js-accordion-btn__icon', item);

            on('show.bs.collapse', () => {
                addClass('is-active', item);
                removeClass('icon-plus', accordionBtnIcon);
                addClass('icon-minus', accordionBtnIcon);
            }, accordionCollapse);

            on('hide.bs.collapse', () => {
                removeClass('is-active', item);
                removeClass('icon-minus', accordionBtnIcon);
                addClass('icon-plus', accordionBtnIcon);
            }, accordionCollapse);
        });
    });
}
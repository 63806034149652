"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, on, trigger, findIn, addClass} from '@elements/dom-utils';
import {add} from "@elements/scroll-animations";


export function init() {
    onFind('.js-fact', function (baseElement) {
        let items = findAllIn('.js-fact__item', baseElement);

        add(
            items,
            calculateAnimationProgress,
            setAnimationProgress
        );
    });
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    //offset 200
    const start = (window.innerHeight-300) / 10 * 9;
    const end = (window.innerHeight-300) / 3;

    return {
        opacity: 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        )

    }

}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    if(progress.opacity > 0){
        addClass('is-active', element);
    }else{
        removeClass('is-active', element);
    }
}

"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';
import {onEnterViewPort, isElementInViewport} from "@elements/viewport-utils";

export function init() {
    onFind('.js-image-row', function (baseElement) {
        let row = findIn('.js-image-row__row', baseElement);
        let lastKnownScrollPosition = 0;
        
        onEnterViewPort(row, () => {
            window.addEventListener('scroll', () => {
                if(isElementInViewport(row)){
                    if(window.scrollY > lastKnownScrollPosition){
                        let style = getComputedStyle(row);
                        let matrix = new WebKitCSSMatrix(style.transform);
                        let transformValue = matchMedia('min-width: 768px') ? 1.5 : 0.5;

                        matrix.m41 = matrix.m41 + transformValue;

                        row.style.transform = matrix;

                    }else{
                        let style = getComputedStyle(row);
                        let matrix = new WebKitCSSMatrix(style.transform);
                        let transformValue = matchMedia('min-width: 768px') ? 1.7 : 0.6;

                        matrix.m41 = matrix.m41 - transformValue;

                        row.style.transform = matrix;
                    }
                }

                lastKnownScrollPosition = window.scrollY;
            }, {capture: true, passive: true});
        })
    });
}

import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {addClass} from "@elements/dom-utils";
export function init() {
    onFind('.js-logo-slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 6;
        let style = document.createElement('style');
        style.innerHTML = '.logo-slider__slides-to-show { --slides-to-show: ' + slidesToShow + ' !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);

        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            },
        });

        addClass('logo-slider__slides-to-show', baseElement);
        addClass('blaze-init', baseElement);
    });
}
"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, on, findIn, addClass, hasClass, setAttribute} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils'
import {add} from '@elements/scroll-animations';

//for starting animation where it stopped
let heroAnimationStoppedAt = 1;
let interval = null;

export function init() {
    window.addEventListener('load', () => {
        onFind('.js-hero', function (baseElement) {

            let title = findIn('.js-hero__title', baseElement);
            let titleData = Object.values(getPrefixedDataSet('hero', title));
            let images = findAllIn('.js-hero__image', baseElement);
            let pauseButton = findIn('.js-hero__pause', baseElement);
            let galleries = findAllIn('.js-hero__gallery', baseElement);
            let i = 1;
            let lastKnownScrollPosition = 0;
            let imageMap = {
                0: ['hero-image-2','hero-image-4','hero-image-9','hero-image-12','hero-image-13'],
                1: ['hero-image-1','hero-image-5','hero-image-8','hero-image-10','hero-image-14'],
                2: ['hero-image-3','hero-image-6','hero-image-7','hero-image-11','hero-image-15'],
            }

            //start animation initially
            addClass('slide-up', title);
            interval = animation(images, title, titleData, i, imageMap);

            //play/pause animation
            on('click', () => {
                if(!hasClass('is-paused', pauseButton)){
                    clearInterval(interval);
                    interval = null;
                    removeClass('slide-up', title);
                    addClass('is-paused', pauseButton);
                }else{
                    addClass('slide-up', title);
                    removeClass('is-paused', pauseButton);
                    interval = animation(images, title, titleData, heroAnimationStoppedAt, imageMap);
                }
            }, pauseButton);

            //stop when out of view
            let debounceTimeout;
            window.addEventListener('scroll', () => {
                clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(() => {
                    lastKnownScrollPosition = scrollHandler(lastKnownScrollPosition, title, titleData, heroAnimationStoppedAt, imageMap, images, pauseButton, baseElement, galleries);
                }, 15);
            }, {capture: true, passive: true});
        });
    });
}

function animation(images, title, titleData, i, imageMap){
    return setInterval(() => {
        //change active image
        images.map(image => {
            removeClass('is-active', image);
            imageMap[i].map(imageId => {
                if(image.id === imageId) {
                    if(!hasClass('is-loaded', image)){
                        switchImage(findIn('.js-hero__image-fullres', image), true);
                        addClass('is-loaded', image);
                        //timeout for image to load
                        setTimeout(() => {
                            addClass('is-active', image);
                        }, 100);
                    }else{
                        addClass('is-active', image);
                    }
                }
            })
        })

        title.innerHTML = titleData[i];

        i++;
        if (i >= 3) i = 0;

        heroAnimationStoppedAt = i;
    }, 3000);
}

function switchImage(image, addLazyLoading = false) {
    if (image.matches('picture')) {
        findAllIn('source, img', image).forEach(function (element) {
            if (addLazyLoading) {
                findAllIn('img', image)
                    .map(setAttribute('loading', 'lazy'))
            }

            if (element.dataset.srcset) {
                element.setAttribute('srcset', element.dataset.srcset);
            }

            if (element.dataset.src) {
                element.setAttribute('src', element.dataset.src);
            }else if(element.tagName === 'IMG' && element.dataset.srcset){
                element.setAttribute('src', element.dataset.srcset.split(' ')[0]);
            }
        });
    } else {
        if (addLazyLoading) {
            image.setAttribute('loading', 'lazy');
        }

        if (image.dataset.srcset) {
            image.setAttribute('srcset', image.dataset.srcset);
        }

        if (image.dataset.src) {
            image.setAttribute('src', image.dataset.src);
        }else if(image.dataset.srcset){
            image.setAttribute('src', image.dataset.srcset.split(' ')[0]);
        }
    }
}

function scrollHandler(lastKnownScrollPosition, title, titleData, heroAnimationStoppedAt, imageMap, images, pauseButton, baseElement, galleries){
    if(window.scrollY > lastKnownScrollPosition){
        if(window.scrollY > 1500 && interval !== null){
            clearInterval(interval);
            interval = null;
            removeClass('slide-up', title);
        }
        if(window.scrollY < 1500){
            //gallery scroll animation
            galleries.forEach((gallery, i) => {
                let style = getComputedStyle(gallery);
                let matrix = new WebKitCSSMatrix(style.transform);
                let transformValue = matchMedia('min-width: 768px') ? 0.5 : 0.1;
                if(i%2 === 0){
                    matrix.m41 = matrix.m41 + transformValue;
                }else{
                    matrix.m41 = matrix.m41 - transformValue;
                }

                gallery.style.transform = matrix;
            });
        }
    }else{
        if(window.scrollY < 1500){
            if(!hasClass('is-paused', pauseButton) && interval === null){
                addClass('slide-up', title);
                interval = animation(images, title, titleData, heroAnimationStoppedAt, imageMap);
            }

            //gallery scroll animation
            galleries.forEach((gallery, i) => {
                let style = getComputedStyle(gallery);
                let matrix = new WebKitCSSMatrix(style.transform);
                let transformValue = matchMedia('min-width: 768px') ? 0.6 : 0.2;
                if(i%2 === 0){
                    matrix.m41 = matrix.m41 - transformValue;
                }else{
                    matrix.m41 = matrix.m41 + transformValue;
                }

                gallery.style.transform = matrix;
            });
        }
    }

    return window.scrollY;
}
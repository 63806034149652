import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, removeClass, addClass} from "@elements/dom-utils";
import BlazeSlider from 'blaze-slider';
export function init() {
    onFind('.js-image-gallery__slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 1;
        let style = document.createElement('style');
        style.innerHTML = '.image-gallery-slider__slides-to-show { --slides-to-show: ' + slidesToShow + ' !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);

        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            }
        });

        addClass('image-gallery-slider__slides-to-show', baseElement);
        addClass('blaze-init', baseElement);

        const unsubscribe = teaserSlider.onSlide(
            (pageIndex, firstVisibleSlideIndex, lastVisibleSlideIndex) => {
                let allSlides = findAllIn('.js-image-gallery__slider-item', baseElement);
                allSlides.map(slide => {
                    removeClass('is-active', slide)
                })
                addClass('is-active', allSlides[pageIndex]);
            });

        if(matchMedia('(min-width: 768px)').matches) {
            teaserSlider.next();
        }
    });
}
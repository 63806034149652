"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on, addClass, removeClass,hasClass,toggleClass, findIn} from '@elements/dom-utils';

export function init () {
    onFind('.js-tabbing__container', function (baseElement){
        let tabDropdownBtn = findAllIn('.js-tabbing__dropdown-btn', baseElement);

        tabDropdownBtn.forEach(function (item){
            let tabItem = findAllIn('.js-tabbing__item', baseElement);
            let tabItemText = tabItem.innerText;
            tabItem.forEach(function (item){
                if(hasClass('active', item)){
                    tabItemText = item.innerHTML;
                    tabDropdownBtn[0].innerHTML = tabItemText;
                }
                on('click', () => {
                    if(hasClass('active', item)){
                        tabItemText = item.innerHTML;
                        tabDropdownBtn[0].innerHTML = tabItemText;
                    }
                }, item);

            });
        });
    });
}
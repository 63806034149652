"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, findAllIn, on, trigger, findIn, addClass} from '@elements/dom-utils';

export function init() {
    onFind('.js-solution', function (baseElement) {
        let items = findAllIn('.js-solution__item', baseElement);
        let i = 0;
        let interval;

        //initial interval
        interval = setInterval(() => {
            animateProgress(baseElement, items[i]);
            if(i === items.length -1){
                i = 0;
            }else{
                i++;
            }
        }, 3000);

        //beak on hover and restart on leave
        items.map(item => {
            let bar = findIn('.js-solution__progress', item);

            on('mouseenter', () => {
                trigger('solution:break', baseElement);
                clearInterval(interval);

                addClass('is-active', item);
                bar.style.width = '100%';
            }, item);

            on('mouseleave', () => {
                removeClass('is-active', item);
                bar.style.width = '0%';

                //start animation again
                interval = setInterval(() => {
                    animateProgress(baseElement, items[i]);
                    if(i === items.length -1){
                        i = 0;
                    }else{
                        i++;
                    }
                }, 3000);
            }, item);
        })
    });
}

function animateProgress(baseElement, item){
    let bar = findIn('.js-solution__progress', item);

    addClass('is-active', item);

    //needed for animation to start
    setTimeout(() => {
        bar.style.width = '100%';
    }, 100);

    //end animation
     setTimeout(() => {
        bar.style.width = '0%';
        removeClass('is-active', item);
    }, 2900);

     //break on hover event
     on('solution:break', () => {
        bar.style.width = '0%';
        removeClass('is-active', item);
     }, baseElement);
}
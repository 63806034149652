import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn} from "@elements/dom-utils";
import {onEnterViewPort, isElementInViewport} from "@elements/viewport-utils";

export function init() {
    onFind('.js-photo-grid', function (baseElement) {
        let i = 1;
        let lastKnownScrollPosition = 0;
        onEnterViewPort(baseElement, () => {
            window.addEventListener('scroll', () => {
                if(isElementInViewport(baseElement)){
                    if (window.scrollY > lastKnownScrollPosition) {
                        let galleries = findAllIn('.js-photo-grid__gallery', baseElement);

                        galleries.map((gallery, i) => {
                            let style = getComputedStyle(gallery);
                            let matrix = new WebKitCSSMatrix(style.transform);
                            let transformValue = matchMedia('min-width: 768px') ? 0.5 : 0.2;
                            if (i % 2 === 0) {
                                matrix.m41 = matrix.m41 - transformValue;
                            } else {
                                matrix.m41 = matrix.m41 + transformValue;
                            }

                            gallery.style.transform = matrix;
                        });
                    } else {
                        if (window.scrollY < lastKnownScrollPosition) {
                            let galleries = findAllIn('.js-photo-grid__gallery', baseElement);

                            galleries.map((gallery, i) => {
                                let style = getComputedStyle(gallery);
                                let matrix = new WebKitCSSMatrix(style.transform);
                                let transformValue = matchMedia('min-width: 768px') ? 0.5 : 0.2;
                                if (i % 2 === 0) {
                                    matrix.m41 = matrix.m41 + transformValue;
                                } else {
                                    matrix.m41 = matrix.m41 - transformValue;
                                }

                                gallery.style.transform = matrix;
                            });
                        }
                    }
                }
                lastKnownScrollPosition = window.scrollY;
            }, {capture: true, passive: true});
        });
    });
}
